import { useMemo } from 'react';

export const useLeasingCalculate = ({ advancePayment, amount, contractPeriod, rate }) => {
  // Сумма финансирования в месяц
  const monthlyNil = ((amount - amount * advancePayment / 100) / contractPeriod) / 1.2;

  // Плата за финансирование в месяц
  const monthlyFee = (amount - amount * advancePayment / 100) * (rate);

  // Месячный платеж по лизингу (без НДС)
  const monthlyPaymentWithoutVat = monthlyNil + monthlyFee;

  // НДС лизингового платежа
  const monthlyVat = monthlyPaymentWithoutVat * 20 / 100;

  // Месячный платеж по лизингу (с НДС)
  const monthlyPayment = monthlyPaymentWithoutVat + monthlyVat;

  // Сумма лизингового договора
  const fullAmount = contractPeriod * monthlyPayment + amount * advancePayment / 100;

  // Суммарная экономия по договору
  const savingAmount = fullAmount / 120 * 20 * 2;

  return useMemo(
    () => ({ fullAmount, monthlyPayment, savingAmount }),
    [fullAmount, monthlyPayment, savingAmount],
  );
}