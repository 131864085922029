import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import { Slider, TextField } from '@material-ui/core';
import { IMaskInput } from 'react-imask';

import { calcMetrics } from '../../../../utils/metrics';
import Styled from './LeasingCalculatorTab.styled';

SliderInput.propTypes = {
  marks: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })),
  mask: PropTypes.string,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number,
  value: PropTypes.number,
}

export default function SliderInput({
  marks,
  mask = 'num руб',
  max,
  min,
  name,
  onChange,
  step,
  value,
}) {
  const inputMask = useMemo(() => [{
    mask,
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        signed: false,
        thousandsSeparator: ' ',
      },
    },
  }], [mask]);

  const handleInputChange = useCallback((newValue) => {
    onChange(name, Number(newValue.replace(/[^0-9]/g, '')));
    calcMetrics();
  }, [name, onChange]);

  const handleBlur = useCallback(() => {
    if (value < min) {
      onChange(name, min);
    } else if (value > max) {
      onChange(name, max);
    }
  }, [max, min, name, onChange, value]);

  const handleSliderChange = useCallback((_, newValue) => {
    onChange(name, newValue);
    calcMetrics();
  }, [name, onChange]);

  return (
    <>
      <TextField
        InputProps={{ inputComponent: IMaskInput }}
        fullWidth
        inputProps={{
          inputMode: 'numeric',
          mask: inputMask,
          max,
          min,
          pattern: '[0-9]*',
          onAccept: handleInputChange,
          onBlur: handleBlur,
          step,
        }}
        sx={Styled.InputField}
        value={String(value)}
      />
      <Slider
        marks={marks}
        max={max}
        min={min}
        onChange={handleSliderChange}
        step={step}
        sx={Styled.InputSlider}
        value={value}
        valueLabelDisplay="off"
      />
    </>
  );
}