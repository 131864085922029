import PropTypes from 'prop-types';
import React, { useCallback, useState, useEffect, useRef } from 'react';

import { Box, Typography, Dialog, IconButton, SvgIcon } from '@material-ui/core';
import ConsultationForm from '@product-site-frontend/shared/components/ConsultationForm';

import popupImage from '../../../../assets/popup.png';
import { LeasingCalcProvider } from "../../LeasingCalcContext";
import LeasingCalcBox from './LeasingCalcBox';
import LeasingCalcDataBox from './LeasingCalcDataBox';
import Styled from './LeasingCalculatorTab.styled';

LeasingCalculatorTab.propTypes = {
  onSwitchToTariffs: PropTypes.func,
  rate: PropTypes.number
}

export default function LeasingCalculatorTab({ onSwitchToTariffs, rate }) {
  const [calcResult, setCalcResult] = useState({
    fullAmount: 0,
    monthlyPayment: 0,
    savingAmount: 0,
  });

  const [dialogShowBefore, setDialogShowBefore] = useState((typeof window !== 'undefined' && !!window?.localStorage?.getItem('callbackModalShown')) || null);
  const [showDialog, setShowDialog] = useState(false);
  const [isFirstCalc, setIsFirstCalc] = useState(true);

  const timeoutId = useRef(null);

  const handleDialogOpen = useCallback(() => {
    if (!dialogShowBefore) {
      setShowDialog(true);
      setDialogShowBefore(true);
      window?.localStorage.setItem('callbackModalShown', 'true');
    }
  }, [dialogShowBefore]);

  const handleCalcResult = useCallback((result) => {
    setCalcResult(result);

    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    if (!dialogShowBefore && !isFirstCalc) {
      timeoutId.current = setTimeout(handleDialogOpen, 15000);
    }

    if (isFirstCalc) {
      setIsFirstCalc(false);
    }
  }, [setCalcResult, dialogShowBefore, handleDialogOpen, isFirstCalc]);

  const onActionButtonClick = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    window?.localStorage.setItem('callbackModalShown', 'true');
    setDialogShowBefore(true);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LeasingCalcProvider>
      <Box>
        <Typography
          sx={{ mb: { xs: 4, md: 5, lg: 6, textAlign: 'center' } }}
          variant="h2"
        >
          Калькулятор лизинга
        </Typography>
        <Typography sx={{ fontSize: 18 }} variant="body1">
          Мы даем оборудование в лизинг любому бизнесу: от небольших{' '}
          предприятий пищевой промышленности до крупных металлообрабатывающих{' '}
          заводов. Предлагаем большой выбор нового и современного оборудования{' '}
          отечественного и иностранного производства для лизинга и последующей покупки.
        </Typography>
        <Box sx={Styled.Container}>
          <LeasingCalcBox onCalc={handleCalcResult} rate={rate} />
          <LeasingCalcDataBox {...calcResult} onActionButtonClick={onActionButtonClick} onSwitchToTariffs={onSwitchToTariffs} />
        </Box>
      </Box>

      <Dialog
        fullWidth={true}
        maxWidth="lg"
        onClose={handleDialogClose}
        open={showDialog}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '920px',
            borderRadius: {
              md: '24px',
              xs: '6px'
            }
          }
        }}
      >
        <Box sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            lg: '440px 1fr'
          }
        }}>
          <Box
            alt="Логотип Arenza"
            component="img"
            src={popupImage}
            sx={{
              display: { xs: "none", lg: "block" },
              width: 440,
            }}
          />
          <Box sx={{
            boxSizing: 'border-box',
            padding: '92px 48px 40px',

            '& form div + div': {
              marginTop: 0
            },

            '& form div + div + div': {
              marginTop: '12px',
              marginBottom: '32px'
            }
          }}>
            <Box sx={{
              fontSize: '30px',
              fontWeight: 800,
              lineHeight: '32px',
              letterSpacing: '0.02em',
              marginBottom: '16px'
            }}>
              Получите точный расчёт по вашим данным за 10 минут!
            </Box>
            <Box sx={{
              fontSize: '20px',
              fontWeight: 300,
              lineHeight: '24px',
              marginBottom: '16px'
            }}>
              Также поможем подобрать поставщика с учётом актуальных акций.
            </Box>
            <ConsultationForm />
          </Box>
        </Box>
        <IconButton aria-label="close" onClick={handleDialogClose} sx={{
          position: 'absolute',
          top: '24px',
          right: '24px'
        }}>
          <SvgIcon>
            <path clipRule="evenodd" d="M5.70735 4.29288C5.31683 3.90235 4.68366 3.90235 4.29314 4.29288C3.90261 4.6834 3.90261 5.31657 4.29314 5.70709L10.5878 12.0017L4.29333 18.2961C3.9028 18.6867 3.9028 19.3198 4.29333 19.7104C4.68385 20.1009 5.31702 20.1009 5.70754 19.7104L12.002 13.4159L18.2964 19.7104C18.6869 20.1009 19.3201 20.1009 19.7106 19.7104C20.1011 19.3198 20.1011 18.6867 19.7106 18.2961L13.4162 12.0017L19.7108 5.70709C20.1013 5.31657 20.1013 4.6834 19.7108 4.29288C19.3203 3.90235 18.6871 3.90235 18.2966 4.29288L12.002 10.5875L5.70735 4.29288Z" fill="#B6BBC2" fillRule="evenodd" />
          </SvgIcon>
        </IconButton>
      </Dialog>
    </LeasingCalcProvider>
  );
}