import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

const defaultContextData = {
  amount: 1500000,
  contractPeriod: 18,
  advancePayment: 20,
};

const defaultContextValue = {
  data: defaultContextData,
  setData: () => { },
};

export const LeasingCalcContext = createContext(defaultContextValue);

export const LeasingCalcProvider = ({ children }) => {
  const [calcData, setCalcData] = useState(defaultContextData);

  return (
    <LeasingCalcContext.Provider value={{ calcData, setCalcData }}>
      {children}
    </LeasingCalcContext.Provider>
  )
};

LeasingCalcProvider.propTypes = { children: PropTypes.node };
