import PropTypes from "prop-types";
import React from "react";

import { Box } from "@material-ui/core";
import { SectionContainer } from "@product-site-frontend/shared";
import { GatsbyImage } from "gatsby-plugin-image";

SectionPartners.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    cards: PropTypes.array,
  }),
};

export default function SectionPartners({ content }) {
  return (
    <SectionContainer title={content.title}>
      <Box
        sx={{
          display: "grid",
          gridRowGap: 48,
          gridColumnGap: 32,
          alignItems: "center",
          gridTemplateColumns: {
            xs: "1fr 1fr",
            md: "repeat(auto-fit, minmax(200px, 1fr))",
            lg: "repeat(auto-fit, minmax(100px, 1fr))",
          },
          gridTemplateRows: {
            xs: "repeat(auto-fit, minmax(100px, 1fr))",
            md: "72px",
          },
          "& .gatsby-image-wrapper": {
            height: "100%",
            width: "100%",
            maxWidth: { xs: 120, md: 160, lg: 200 },
            margin: "0 auto",
            "& img": {
              objectFit: "contain !important",
            },
          },
        }}
      >
        {(content.cards || []).map(card => (
          <GatsbyImage
            alt={card.alternativeText}
            image={card.localFile.childImageSharp.gatsbyImageData}
            key={card.id}
          />
        ))}
      </Box>
    </SectionContainer>
  );
}
