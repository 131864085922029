import PropTypes from "prop-types";
import React from "react";

import { Box } from "@material-ui/core";
import { SectionContainer, Card, WrapperButton } from "@product-site-frontend/shared";
import { Swiper, SwiperSlide } from "swiper/react";

export default function SectionBlog({ articles }) {
  return (
    <SectionContainer title="Полезные статьи">
      <Box
        sx={{
          display: { xs: "block", lg: "none" },
          "& .swiper-container": {
            pb: 3,
            mx: { xs: -3, md: -4 },
          },
          "& .swiper-slide-link": {
            display: "flex",
            mb: { xs: 5, lg: 0 },
            textDecoration: "none",
            width: "100%",
            "&:not(:last-child)": {
              mr: 4,
            },
          },
          "& .swiper-container-horizontal > .swiper-pagination-bullets": {
            bottom: 0,
          },
        }}
      >
        <Swiper
          centeredSlides
          // navigation
          loop
          pagination={{
            clickable: true,
          }}
          roundLengths
          slidesPerView={1.3}
          spaceBetween={24}
        >
          {articles.map(props => (
            // eslint-disable-next-line react/prop-types
            <SwiperSlide key={props.slug}>
              <Card {...props} href={"blog"} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      <Box display={{ xs: "none", lg: "flex" }} width={1}>
        {articles.slice(0, 4).map((props, i) => (
          <Box key={i} ml={i ? 4 : 0} width={1 / 4}>
            <Card {...props} href={"blog"} />
          </Box>
        ))}
      </Box>

      {articles?.length > 4 && (
        <Box sx={{
          textAlign: 'center',
          marginTop: {
            xs: '16px',
            md: '56px'
          }
        }}>
          <WrapperButton colorScheme={"greyStrokeRounded"} component="a" href={"/blog"}>
            Читать все
          </WrapperButton>
        </Box>
      )}
    </SectionContainer>
  );
};

SectionBlog.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      publishedAt: PropTypes.string,
      slug: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
};
