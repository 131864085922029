const Container = {
  display: 'flex',
  flexDirection: { xs: 'column', lg: 'row' },
  py: 4,
}

const CalcBox = {
  width: { xs: '100%', lg: '50%' },
  ml: { xs: 0, lg: '60px' },
  mr: { xs: 0, lg: '40px' },
  padding: { xs: '42px 0 46px', lg: '42px 40px 46px 0' },
}

const InputField = {
  height: 65,
  mt: 2,
  boxSizing: 'border-box',

  '& .MuiInputBase-input': {
    fontSize: 21,
    fontWeight: 700,
    padding: '11px 14px 16px',
  },
}

const InputSlider = {
  padding: '13px 0 !important',
  position: 'absolute',
  left: 0,
  top: 'calc(100% - 32px)',

  '& .MuiSlider-thumb': {
    background: 'white',
    border: '5px solid currentColor',
  },

  '& .MuiSlider-markLabel': {
    fontSize: 16,
    top: '30px !important',
    transform: 'translateX(0)',
    color: '#7d7d7d',

    '&[data-index="1"]': {
      left: 'auto !important',
      right: 0,
    },
  },
}

const DataBox = {
  width: { xs: '100%', lg: '50%' },
  backgroundColor: '#f8f8f8',
  borderRadius: { xs: '10px', sm: '25px', md: '50px' },
  ml: { xs: 0, lg: '40px' },
  mr: { xs: 0, lg: '60px' },
  padding: { xs: '25px', sm: '42px 30px', md: '42px 40px 46px 50px' },
}

const ApplicationButton = {
  borderRadius: '11px',
  fontSize: 22,
  px: '40px',
  textTransform: 'none',
  width: 'auto',
}

export default {
  Container,
  CalcBox,
  InputField,
  InputSlider,
  DataBox,
  ApplicationButton,
}